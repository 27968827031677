import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import RelatedPostCard from "../components/RelatedPostCard";
import RelatedPostsLabel from "./RelatedPostsLabel";

const Container = styled.div`
  margin-top: 15px;
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

// const slugs = [
//   "long-tail-cast-on",
//   "slip-knot",
//   "the-first-post",
//   "test-post-four"
// ];

const filterDevPosts = edge =>
  process.env.NODE_ENV === "development" ||
  !edge.node.frontmatter.developmentpost;

const RelatedPostsList = ({
  slug,
  category,
  exclude_series,
  related_posts
}) => {
  const data = useStaticQuery(graphql`
    query PossiblyRelatedPostsQuery {
      allMarkdownRemark(
        filter: { fields: { sourceInstanceName: { eq: "markdown-pages" } } }
        sort: { fields: frontmatter___posted_at, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              developmentpost
              thumbnail
              slug
              category
              series_slug
              series_volume
              tags
              posted_at
            }
          }
        }
      }
    }
  `);
  data.allMarkdownRemark.edges = data.allMarkdownRemark.edges
    .filter(filterDevPosts)
    .filter(edge => edge.node.frontmatter.series_slug !== exclude_series)
    .filter(edge => edge.node.frontmatter.slug !== slug);
  let slugs = related_posts || [];
  // in most cases users will explicitly set 4 related posts
  // if they don't we want to make sure we add posts until we get
  // to a multiple of 4. bc then the grid looks nice.
  let posts_to_add = slugs.length === 4 ? 0 : 4 - (slugs.length % 4);
  // begin filling if there are
  //   (1) no related_posts passed in, or
  //   (2) some number not divisible by 4
  if (slugs.length === 0 || slugs.length % 4 > 0) {
    // first pass: just add anything in the same category
    for (let post of data.allMarkdownRemark.edges) {
      if (posts_to_add === 0) {
        break;
      }
      if (
        post.node.frontmatter.category === category &&
        !slugs.includes(post.node.frontmatter.slug)
      ) {
        slugs.push(post.node.frontmatter.slug);
        posts_to_add = posts_to_add - 1;
      }
    }
  }

  if (posts_to_add > 0) {
    // second pass: just add the most recent posts
    for (let post of data.allMarkdownRemark.edges) {
      if (posts_to_add === 0) {
        break;
      }
      if (!slugs.includes(post.node.frontmatter.slug)) {
        slugs.push(post.node.frontmatter.slug);
        posts_to_add = posts_to_add - 1;
      }
    }
  }
  return (
    <Container>
      <RelatedPostsLabel text={`Related Articles`} />
      <PostsGrid>
        {slugs.map(slug => (
          <RelatedPostCard key={slug} slug={slug} />
        ))}
      </PostsGrid>
    </Container>
  );
};

export default RelatedPostsList;
