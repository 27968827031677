import React from "react"
import styled from "styled-components"
import ToggleButton from "react-toggle-button"

import BlogVideo from "../components/BlogVideo"
import useToggle from "../utils/useToggle"

const CenteredGridWrapper = styled.div`
  display: grid;
  justify-items: center;
  margin-bottom: 15px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.span`
  margin: 10px;
  color: ${({ selected }) => (selected ? "black" : "inherit")};
  font-weight: ${({ selected }) => (selected ? "bold" : "inherit")};
`

const VideoContainer = styled.div`
  display: ${({ visible }) => (visible ? "block" : "none !important")};
`

const ToggleVideo = ({
  block: { video_a_name, video_a, video_b_name, video_b },
}) => {
  const [isPlaying, togglePlaying] = useToggle()

  return (
    <CenteredGridWrapper>
      <ButtonContainer>
        <Label selected={!isPlaying}>{video_b_name}</Label>
        <ToggleButton
          value={isPlaying}
          inactiveLabel={""}
          activeLabel={""}
          onToggle={togglePlaying}
        />
        <Label selected={isPlaying}>{video_a_name}</Label>
      </ButtonContainer>
      <VideoContainer visible={!isPlaying}>
        <BlogVideo
          src={video_a}
          controls={true}
          loop={true}
          autoPlay={true}
          muted={true}
        />
      </VideoContainer>
      <VideoContainer visible={isPlaying}>
        <BlogVideo
          src={video_b}
          controls={true}
          loop={true}
          autoPlay={true}
          muted={true}
        />
      </VideoContainer>
    </CenteredGridWrapper>
  )
}

export default ToggleVideo
