import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import RelatedPostCard from "../components/RelatedPostCard"
import RelatedPostsLabel from "./RelatedPostsLabel"

const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const SeriesPostsList = ({ slug, series_slug, series_volume }) => {
  const data = useStaticQuery(graphql`
    query othersInSeriesQuery {
      allMarkdownRemark(
        filter: {
          fields: { sourceInstanceName: { eq: "markdown-pages" } }
          frontmatter: { series_slug: { ne: null } }
        }
        sort: { fields: frontmatter___series_volume }
      ) {
        edges {
          node {
            frontmatter {
              title
              developmentpost
              thumbnail
              slug
              category
              series_slug
              series_volume
            }
          }
        }
      }
    }
  `)
  const slugs = data.allMarkdownRemark.edges.reduce(
    (tally, edge) =>
      edge.node.frontmatter.series_slug === series_slug &&
      edge.node.frontmatter.series_volume !== series_volume
        ? [...tally, edge.node.frontmatter.slug]
        : tally,
    []
  )
  return (
    <Container>
      <RelatedPostsLabel text={`Other Posts in this Series`} />
      <PostsGrid>
        {slugs.map(slug => (
          <RelatedPostCard key={slug} slug={slug} />
        ))}
      </PostsGrid>
    </Container>
  )
}

export default SeriesPostsList
