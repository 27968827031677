import React from "react"
import styled from "styled-components"

import BlogSingleImage from "../components/BlogSingleImage"

const StyledSingleImage = styled(BlogSingleImage)`
  display: block !important;
`

const Wrapper = styled.figure`
  width: 70%;
  margin: 1em auto;
  .gatsby-image-wrapper {
      border-radius: 10px;
  }
`

const StandaloneImageTemplate = ({ mapping, slug }) => (
  <Wrapper>
    <StyledSingleImage src={mapping[slug].image} alt={mapping[slug].alt} />
    <figcaption>{mapping[slug].alt}</figcaption>
  </Wrapper>
)

export default StandaloneImageTemplate
