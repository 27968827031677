import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import PillTag from "../components/PillTag";
import BlogHeaderImage from "../components/BlogHeaderImage";
import ForestryBlock from "../components/ForestryBlock";
import RelatedPostsList from "../components/RelatedPostsList";
import SeriesPostsList from "../components/SeriesPostsList";

const PostContainer = styled.div`
  font-size: large;
  line-height: 1.5;
  font-weight: regular;
  h1,
  h3 {
    margin-bottom: 5px;
  }
  h2 {
    border-bottom: solid 2px #ebebeb;
  }
  h1 {
    font-size: x-large;
  }
  main {
    padding: 0 1em;
    margin: 0;
  }
`;

const PostHeader = styled.div`
  text-align: center;
`;

const MobileTitle = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const popoverMapping = frontmatter.block.reduce(
    (tally, current) =>
      current.template === "popover-content"
        ? Object.assign(tally, {
            [current.slug]: {
              content: current.content,
              image: current.image,
              alt: current.alt
            }
          })
        : tally,
    {}
  );

  return (
    <PostContainer>
      <div className="blog-post">
        <PostHeader>
          <MobileTitle>
            <h1>{frontmatter.title}</h1>
            <time>{frontmatter.posted_at}</time>
          </MobileTitle>
          <BlogHeaderImage
            src={frontmatter.thumbnail}
            title={frontmatter.title}
            posted_at={frontmatter.posted_at}
          />
          <div style={{ marginBottom: "10px" }}>
            {frontmatter.tags.map(tag => (
              <Link key={tag} to={`/tags`} state={{ initialSelected: tag }}>
                <PillTag tag={tag} />
              </Link>
            ))}
          </div>
        </PostHeader>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div>
          {frontmatter.block &&
            frontmatter.block.map((block, index) => (
              <ForestryBlock
                key={index}
                block={block}
                popoverMapping={popoverMapping}
              />
            ))}
        </div>
      </div>
      {frontmatter.series_slug && (
        <SeriesPostsList
          slug={frontmatter.slug}
          series_slug={frontmatter.series_slug}
          series_volume={frontmatter.series_volume}
        />
      )}
      <RelatedPostsList
        slug={frontmatter.slug}
        category={frontmatter.category}
        exclude_series={frontmatter.series_slug}
        related_posts={frontmatter.relatedposts}
      />
    </PostContainer>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        posted_at(formatString: "MMMM DD, YYYY")
        slug
        title
        tags
        thumbnail
        series_slug
        series_volume
        category
        relatedposts
        block {
          template
          paragraph
          image
          alt
          text
          style
          slug
          content
          file
          standalone_image
          video_a
          video_a_name
          video_b
          video_b_name
        }
      }
    }
  }
`;
