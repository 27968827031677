import React from "react"
import styled from "styled-components"

import { StaticQuery, graphql } from "gatsby"

const FullWidthVideo = styled.video`
  width: 100%;
`

function getFilenameFromPath(path) {
  const pieces = path.split("/")
  return pieces[pieces.length - 1]
}

function renderImage(file, controls, loop, autoPlay, muted, playsInline) {
  return (
    <FullWidthVideo
      controls={controls}
      loop={loop}
      autoPlay={autoPlay}
      muted={muted}
      playsInline={playsInline}
    >
      <source src={file.node.publicURL} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </FullWidthVideo>
  )
}
const BlogVideo = function(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { extension: { regex: "/mp4/" } }) {
            edges {
              node {
                extension
                relativePath
                publicURL
              }
            }
          }
        }
      `}
      render={({ images }) => {
        // let imageSrc = props.src
        if (props.optimize) {
          const imageSrc =
            props.src.slice(0, -4) + "_crf35" + props.src.slice(-4)
          const edge = images.edges.find(
            image => image.node.relativePath === getFilenameFromPath(imageSrc)
          )
          if (edge) {
            return renderImage(
              edge,
              props.controls,
              props.loop,
              props.autoPlay,
              props.muted,
              props.playsInline
            )
          }
        }

        return renderImage(
          images.edges.find(
            image => image.node.relativePath === getFilenameFromPath(props.src)
          ),
          props.controls,
          props.loop,
          props.autoPlay,
          props.muted,
          props.playsInline
        )
      }}
    />
  )
}
export default BlogVideo
