import React from "react"
import Tippy from "@tippyjs/react"
import styled from "styled-components"
import "tippy.js/dist/tippy.css"

import Thumbnail from "../components/thumbnail"

const StyledWindow = styled.div`
  font-size: large;
`

const PopoverWindow = styled.div`
  p {
    margin-bottom: 5px;
  }
`

const TipWindow = ({ html, image, alt }) => (
  <StyledWindow>
    <PopoverWindow dangerouslySetInnerHTML={{ __html: html }} />
    {image && (
      <>
        <Thumbnail src={image} />
        <figcaption>{alt}</figcaption>
      </>
    )}
  </StyledWindow>
)

const StyledText = styled.span`
  color: var(--highlight-color);
`

const PopoverTemplate = ({ children, mapping, slug }) => (
  <Tippy
    interactive
    animation="shift-away"
    content={
      <TipWindow
        html={mapping[slug].content}
        image={mapping[slug].image}
        alt={mapping[slug].alt}
      />
    }
  >
    <StyledText>{children}</StyledText>
  </Tippy>
)

export default PopoverTemplate
