import React from "react";
import styled from "styled-components";

const Label = styled.h3`
  color: rgb(165, 178, 189);
  font-size: small;
  border-bottom: 1px solid rgb(213, 220, 225);
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px !important;
  span {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white none repeat scroll 0% 0%;
    padding: 0px 10px;
  }
`;

const RelatedPostsLabel = ({ text }) => (
  <Label>
    <span>{text}</span>
  </Label>
);

export default RelatedPostsLabel;
