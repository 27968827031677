import React from "react"
import GifPlayer from "react-gif-player"

import { StaticQuery, graphql } from "gatsby"

function getFilenameFromPath(path) {
  const pieces = path.split("/")
  return pieces[pieces.length - 1]
}

function renderImage(file, alt) {
  return (
    typeof window !== "undefined" && <GifPlayer gif={file.node.publicURL} />
  )
}
const Gif = function(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          gifs: allFile(filter: { extension: { regex: "/gif/" } }) {
            edges {
              node {
                relativePath
                publicURL
              }
            }
          }
        }
      `}
      render={({ gifs }) => {
        return renderImage(
          gifs.edges.find(
            gif => gif.node.relativePath === getFilenameFromPath(props.src)
          ),
          props.alt
        )
      }}
    />
  )
}
export default Gif
