import React from "react"
import styled from "styled-components"

const Tag = styled.button`
  font-size: 0.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 0 1.5rem;
  border-radius: 20px;
  margin: 0.5rem;
`

const PillTag = ({ tag }) => <Tag>{tag}</Tag>

export default PillTag
