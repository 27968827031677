import React from "react"
import Tippy from "@tippyjs/react"
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"
import "tippy.js/dist/tippy.css"

import Thumbnail from "../components/thumbnail"
import MyImg from "../components/MyImg"

const StyledWindow = styled.div`
  font-size: large;
`

const PopoverWindow = styled.div`
  p {
    margin-bottom: 5px;
  }
`

const PostLink = styled(Link)`
  display: block;
  border-radius: 4px;
  background: rgb(241, 243, 245) none repeat scroll 0% 0%;
  overflow: hidden;
  @media(max-width: 400px) {
    width: 100px;
  }
`

const PostImg = styled.figure`
  position: relative;
  margin: 0px;
  width: 100%;
  //   height: 110px;
  overflow: hidden;
`

const PostDetails = styled.p`
  font-size: small;
  padding: 15px;
  text-align: center;
  line-height: 1.5em;
  color: rgb(29, 43, 54);
  margin: 0px;
  span {
    display: block;
    margin-bottom: 2px;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    color: rgb(147, 161, 176);
    font-size: 9.5px;
    font-weight: 500;
  }
`

const PostDescription = styled.div`
  width: 100%;
  padding: 15px;
  //   margin-top: 2.5rem;
  font-size: small;
  color: black;
`

const renderPost = (post, title, description) => (
  <PostLink to={`/blog/${post.node.frontmatter.slug}`}>
    <PostImg>
      <MyImg src={post.node.frontmatter.thumbnail} />
    </PostImg>
    <PostDetails>
      <span>{post.node.frontmatter.category}</span>
      {title || post.node.frontmatter.title}
    </PostDetails>
    <PostDescription>
      {description || post.node.frontmatter.description}
    </PostDescription>
  </PostLink>
)

const TipWindow = ({ link, title, description }) => (
  <StaticQuery
    query={graphql`
      query PopoverLinkQuery {
        posts: allMarkdownRemark(
          filter: { fields: { sourceInstanceName: { eq: "markdown-pages" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                thumbnail
                slug
                category
                posted_at(fromNow: true)
                description
              }
            }
          }
        }
      }
    `}
    render={({ posts }) => {
      return renderPost(
        posts.edges.find(post => post.node.frontmatter.slug === link),
        title,
        description
      )
    }}
  />
)

const StyledText = styled.span`
  color: var(--highlight-color);
`

const PopoverLink = ({ children, link, title, description }) => (
  <Tippy
    interactive
    animation="shift-away"
    content={<TipWindow link={link} title={title} description={description} />}
  >
    <StyledText>{children}</StyledText>
  </Tippy>
)

export default PopoverLink
