import React from "react"
import styled from "styled-components"

const BaseCallout = styled.div`
  color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  font-size: medium;
  padding: 1rem;
  margin: 1.5rem 0;
  p {
    margin-bottom: 10px;
  }
  div:last-child {
    margin-bottom: 5px;
    p {
      margin-bottom: 0px;
    }
  }
`

const WarningCallout = styled(BaseCallout)`
  border: 2px solid #fed356;
  background: #fff8e4;
`

const DangerCallout = styled(BaseCallout)`
  border: 2px solid #721c24;
  background: #f8d7da;
`

const PrimaryCallout = styled(BaseCallout)`
  border: 2px solid #004085;
  background: #cce5ff;
`

const renderAllText = (textArr, renderFn) =>
  JSON.parse(textArr).map((text, idx) => <div key={idx}>{renderFn(text)}</div>)

const Callout = ({ text, style, renderFn }) => (
  <>
    {style === "warning_yellow" && (
      <WarningCallout>{renderAllText(text, renderFn)}</WarningCallout>
    )}
    {style === "danger_red" && (
      <DangerCallout>{renderAllText(text, renderFn)}</DangerCallout>
    )}
    {style === "primary_blue" && (
      <PrimaryCallout>{renderAllText(text, renderFn)}</PrimaryCallout>
    )}
  </>
)

export default Callout
