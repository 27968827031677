import React, { Component } from "react"
import styled from "styled-components"
import rehypeReact from "rehype-react"

import BlogSingleImage from "../components/BlogSingleImage"
import Gif from "../components/Gif"
import Callout from "../components/Callout"
import PopoverTemplate from "../components/PopoverTemplate"
import PopoverLink from "../components/PopoverLink"
import StandalongImageTemplate from "../components/StandaloneImageTemplate"
import BlogVideo from "../components/BlogVideo"
import ToggleVideo from "../components/ToggleVideo"

// const renderAst = new rehypeReact({
//   createElement: React.createElement,
//   components: { "popover-template": PopoverTemplate },
// }).Compiler

const CenteredGridWrapper = styled.div`
  display: grid;
  justify-items: center;
  margin-bottom: 15px;
`

const StyledSingleImage = styled(BlogSingleImage)`
  display: block !important;
`

const WideWrapper = styled.figure`
  width: 90%;
  margin: 1em auto;
`

const Wrapper = styled.figure`
  width: 50%;
  margin: 1em auto;
`

class ForestryBlock extends Component {
  render() {
    if (this.props.block.template === "markdown-paragraph") {
      return (
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: this.props.block.paragraph }}
          />
        </div>
      )
    }
    if (this.props.block.template === "custom-el-markdown-paragraph") {
      const PopoverTemplateWithProps = props => (
        <PopoverTemplate mapping={this.props.popoverMapping} {...props} />
      )
      const PopoverLinkWithProps = props => (
        <PopoverLink mapping={this.props.popoverMapping} {...props} />
      )
      const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: {
          "popover-template": PopoverTemplateWithProps,
          "popover-link": PopoverLinkWithProps,
        },
      }).Compiler
      return (
        <div>
          <div>{renderAst(JSON.parse(this.props.block.paragraph))}</div>
        </div>
      )
    }
    if (this.props.block.template === "single-image") {
      return (
        <Wrapper>
          <StyledSingleImage
            src={this.props.block.image}
            alt={this.props.block.alt}
          />
          <figcaption>{this.props.block.alt}</figcaption>
        </Wrapper>
      )
    }
    if (this.props.block.template === "wide-image") {
      return (
        <WideWrapper>
          <StyledSingleImage
            src={this.props.block.image}
            alt={this.props.block.alt}
          />
          <figcaption>{this.props.block.alt}</figcaption>
        </WideWrapper>
      )
    }
    if (this.props.block.template === "gif") {
      return (
        <WideWrapper>
          <Gif src={this.props.block.image} />
          <figcaption>{this.props.block.alt}</figcaption>
        </WideWrapper>
      )
    }
    if (this.props.block.template === "callout") {
      const PopoverTemplateWithProps = props => (
        <PopoverTemplate mapping={this.props.popoverMapping} {...props} />
      )
      const PopoverLinkWithProps = props => (
        <PopoverLink mapping={this.props.popoverMapping} {...props} />
      )
      const ImageTemplateWithProps = props => (
        <StandalongImageTemplate
          mapping={this.props.popoverMapping}
          {...props}
        />
      )
      const justADiv = styled.div``
      const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: {
          "popover-template": PopoverTemplateWithProps,
          "image-template": ImageTemplateWithProps,
          "popover-link": PopoverLinkWithProps,
          p: justADiv,
        },
      }).Compiler
      return (
        <Callout
          text={this.props.block.text}
          style={this.props.block.style}
          renderFn={renderAst}
        />
      )
    }
    if (this.props.block.template === "video") {
      return (
        <CenteredGridWrapper>
          <BlogVideo
            src={this.props.block.file}
            controls={true}
            loop={true}
            autoPlay={true}
            muted={true}
          />
        </CenteredGridWrapper>
      )
    }
    if (this.props.block.template === "togglevideo") {
      return <ToggleVideo block={this.props.block} />
    }
    return null
  }
}

export default ForestryBlock
