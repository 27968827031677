import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const ImageContainer = styled.div`
  position: relative;
  text-align: left;
  max-height: 400px;
  overflow: hidden;
  margin-bottom: 10px;
  h1 {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    @media (max-width: 768px) {
      display: none;
    }
  }
  h1 {
    color: white;
    font: bold 24px/45px Helvetica, Sans-Serif;
    letter-spacing: -1px;
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
  }
  h1 span.spacer {
    padding: 0 5px;
    background: rgba(0, 0, 0, 0.7);
  }
`

function getFilenameFromPath(path) {
  const pieces = path.split("/")
  return pieces[pieces.length - 1]
}

function renderImage(file, title, posted_at) {
  return (
    <ImageContainer>
      <Img fluid={file.node.childImageSharp.fluid} />
      <h1>
        <span>
          {title}
          <br />
          {posted_at}
        </span>
      </h1>
    </ImageContainer>
  )
}
const BlogHeaderImage = function(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images }) => {
        return renderImage(
          images.edges.find(
            image => image.node.relativePath === getFilenameFromPath(props.src)
          ),
          props.title,
          props.posted_at
        )
      }}
    />
  )
}
export default BlogHeaderImage
